<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-end mt-2">
                            <!-- <div>
                            <button type="button" class="btn btn-primary mb-2 mr-2 float-left" @click="resetOrderData">
                                <i class="mdi mdi-arrow-left mr-1"></i> Go back
                            </button>
                        </div> -->
                            <div>
                                <b-button-group class="mb-2">
                                    <!-- <b-button variant="secondary"> <i class="mdi mdi-printer mr-1"></i> Customer Label </b-button> -->
                                    <b-button
                                        v-if="all_products_groups_loading_status == false"
                                        @click="addProductGroup"
                                        class="ml-2"
                                        variant="success"
                                    >
                                        <i class="mdi mdi-plus mr-1"></i> Add Product Group</b-button
                                    >
                                </b-button-group>
                            </div>
                        </div>
                        <ProductGroupsTable @editProductGroup="editProductGroup" @linkProductsToGroup="linkProductsToGroup" />
                    </div>
                </div>
            </div>
        </div>

        <AddProductGroupModal v-if="show_modal" />
        <EditProductGroupModal v-if="show_modal_edit" />
        <LinkProductsToGroupModal v-if="show_modal_link_products" />
    </div>
</template>

<script>
import ProductGroupsTable from "./tables/productGroupsTable.vue";
import AddProductGroupModal from "./modals/addProductGroupModal.vue";
import EditProductGroupModal from "./modals/editProductGroupModal.vue";
import LinkProductsToGroupModal from "./modals/linkProductsToGroupModal.vue";
import { productMethods, productComputed } from "@/state/helpers";
//eslint-disable-next-line
import Swal from "sweetalert2";

export default {
    components: { ProductGroupsTable, AddProductGroupModal, EditProductGroupModal, LinkProductsToGroupModal },
    data: () => ({
        show_modal: false,
        show_modal_edit: false,
        show_modal_link_products: false,
    }),
    computed: {
        ...productComputed,
    },
    methods: {
        ...productMethods,

        addProductGroup() {
            this.show_modal = true;
            this.$nextTick(() => {
                this.$bvModal.show("addProductGroupModal");
                this.$root.$once("bv::modal::hide", (event) => {
                    if (event.type == "hide" && event.componentId == "addProductGroupModal") {
                        this.show_modal = false;
                    }
                });
            });
        },
        editProductGroup() {
            //eslint-disable-next-line
            console.log("run");
            this.show_modal_edit = true;
            this.$nextTick(() => {
                this.$bvModal.show("editProductGroupModal");
                this.$root.$once("bv::modal::hide", (event) => {
                    if (event.type == "hide" && event.componentId == "editProductGroupModal") {
                        this.show_modal_edit = false;
                    }
                });
            });
        },
        linkProductsToGroup() {
            this.show_modal_link_products = true;
            this.$nextTick(() => {
                this.$bvModal.show("linkProductsToGroupModal");
                this.$root.$once("bv::modal::hide", (event) => {
                    if (event.type == "hide" && event.componentId == "linkProductsToGroupModal") {
                        this.show_modal_link_products = false;
                    }
                });
            });
        },
    },
    mounted() {},
};
</script>

<style></style>
