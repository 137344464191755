<template>
    <div>
        <div v-if="all_products_groups_loading_status == true" class="text-center">
            <b-spinner small type="grow"></b-spinner>
            Loading product groups...
        </div>
        <div v-else>
            <webix-ui :config="ui" v-model="all_product_groups"></webix-ui>
            <div id="productsGroupsTablePaging"></div>
        </div>
    </div>
</template>

<script>
import { productMethods, productComputed } from "@/state/helpers";
import Swal from "sweetalert2";

export default {
    data: () => ({
        groups: [{}, {}, {}],
    }),
    computed: {
        ...productComputed,
        ui() {
            //eslint-disable-next-line
            let el = this;
            //eslint-disable-next-line
            let ui = {
                id: "productsGroupsTable",
                view: "datatable",
                css: "datatableUsers webix_header_border webix_data_border",
                autoheight: true,
                //hover: "datatableHover",
                minHeight: 60,
                minColumnWidth: 120,
                rowHeight: 40,
                scrollY: false,
                scrollX: true,
                resizeColumn: true,
                select: false,
                pager: {
                    template: "{common.prev()} {common.pages()} {common.next()}",
                    container: "productsGroupsTablePaging",
                    size: 15,
                    group: 5,
                },
                columns: [
                    {
                        id: "actions",
                        header: "Actions",
                        width: 50,
                        template: function () {
                            return `<div class="tableIcons">
                                <i class="mdi mdi-eye viewAction"></i>
                                <i v-b-tooltip.hover title="Edit Group" class="mdi mdi-pencil editAction"></i>
                                <i v-b-tooltip.hover title="Delete Group" class="mdi mdi-trash-can deleteAction"></i>
                            </div>`;
                        },
                    },

                    {
                        id: "name",
                        header: "Group Name",
                        tooltip: false,
                        // fillspace: 1,
                        width: 200,
                    },
                    {
                        id: "description",
                        header: "Description",
                        tooltip: false,
                        fillspace: 1,
                    },
                ],
                on: {},
                onClick: {
                    //eslint-disable-next-line
                    viewAction: function (id, e, trg) {
                        let item = this.getItem(e.row);
                        //set the current row as selected
                        let el = this.$scope.$parent;
                        el.setSelctedProductGroupDetails(item);
                        el.$router.push(`/product-groups/${item.id}`);
                        // el.$emit("editProductGroup");
                    },
                    //eslint-disable-next-line
                    editAction: function (id, e, trg) {
                        let item = this.getItem(e.row);
                        //set the current row as selected
                        let el = this.$scope.$parent;
                        el.setSelctedProductGroupDetails(item);

                        el.$emit("editProductGroup");
                    },
                    //eslint-disable-next-line
                    linkProductsAction: function (id, e, trg) {
                        let item = this.getItem(e.row);
                        let el = this.$scope.$parent;
                        el.setSelctedProductGroupDetails(item);

                        el.$emit("linkProductsToGroup");
                    },
                    //eslint-disable-next-line
                    deleteAction: function (id, e, trg) {
                        let item = this.getItem(e.row);
                        let el = this.$scope.$parent;
                        el.setSelctedProductGroupDetails(item);
                        el.handleSwal(item);
                    },
                },
            };
            return ui;
        },
    },
    methods: {
        ...productMethods,
        handleSwal(item) {
            Swal.fire({
                title: `Delete product group`,
                text: `Are you sure you want to delete group: "${item.name}", you can't undo this`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete!",
            }).then((result) => {
                if (result.value) {
                    this.deleteProductGroup(item).then(() => {
                        Swal.fire("Deleted", `Product group "${item.name}" deleted`, "success");

                        this.setAllProductGroups();
                    });
                }
            });
        },
    },
    mounted() {
        //store
        this.setAllProductGroups();
    },
};
</script>

<style></style>
