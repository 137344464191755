<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import ProductGroups from "@/components/product-properties/product-groups/productGroupsView.vue";

/**
 * Starter component
 */
export default {
    page: {
        title: "Manage Product Groups",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader, ProductGroups },
    data() {
        return {
            title: "Manage Product Groups",
            // items: [
            //     {
            //         text: "Dashboard",
            //         href: "/",
            //     },
            //     {
            //         text: "Properties",
            //         active: true,
            //     },
            // ],
        };
    },
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" />
        <ProductGroups />
    </Layout>
</template>
