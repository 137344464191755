<template>
    <b-modal title="Edit A Product Group" id="editProductGroupModal" hide-footer>
        <b-overlay :show="busySaving">
            <b-form>
                <b-form-group id="example-input-group-1" label="Name" label-for="example-input-1">
                    <b-form-input
                        id="example-input-1"
                        name="example-input-1"
                        v-model="$v.form.name.$model"
                        :state="validateState('name')"
                        aria-describedby="input-1-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field and must be at least 2 characters.</b-form-invalid-feedback
                    >
                </b-form-group>

                <b-form-group id="example-input-group-1" label="Description" label-for="example-input-1">
                    <b-form-input
                        id="example-input-1"
                        name="example-input-1"
                        v-model="$v.form.description.$model"
                        :state="validateState('description')"
                        aria-describedby="input-1-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field and must be at least 2 characters.</b-form-invalid-feedback
                    >
                    <b-form-checkbox
                        id="checkbox-1"
                        v-model="form.display_material"
                        name="checkbox-1"
                        value="true"
                        unchecked-value="false"
                        class="mt-4 mb-2 ml-1"
                        switch
                    >
                        Display Material
                    </b-form-checkbox>
                </b-form-group>
                <div class="modal-footer">
                    <b-button @click="onSubmit" variant="primary">Update Product Group</b-button>
                    <button type="button" class="btn btn-secondary" @click="closeModal()">Close</button>
                </div>
            </b-form>
        </b-overlay>
    </b-modal>
</template>

<script>
import { productComputed, productMethods } from "@/state/helpers";
//eslint-disable-next-line
import { validationMixin } from "vuelidate";
//eslint-disable-next-line
import { required, minLength } from "vuelidate/lib/validators";
//eslint-disable-next-line
import Swal from "sweetalert2";

export default {
    data: () => ({
        busySaving: false,
        formSubmit: false,
        form: {
            id: "",
            name: "",
            description: "",
            linked_product_ids: [],
            linked_role_ids: [],
            display_material: false,
        },
    }),
    validations: {
        form: {
            name: {
                required,
                minLength: minLength(2),
            },
            description: {
                required,
                minLength: minLength(3),
            },
        },
    },
    computed: {
        ...productComputed,
    },
    methods: {
        ...productMethods,
        validateState(name) {
            if (this.formSubmit) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            }
        },
        closeModal() {
            this.$bvModal.hide("editProductGroupModal");
        },
        // handleSwal(item) {
        //     Swal.fire({
        //         title: `Update group`,
        //         text: "Are you sure?",
        //         icon: "warning",
        //         showCancelButton: true,
        //         confirmButtonColor: "#34c38f",
        //         cancelButtonColor: "#f46a6a",
        //         confirmButtonText: "Yes, delete!",
        //     }).then((result) => {
        //         if (result.value) {
        //             this.ready = false;

        //             this.saveProductGroup(this.form).then(() => {
        //                 this.busySaving = false;

        //                 Swal.fire(`Product group updated`, "", "success");

        //                 this.closeModal();

        //                 this.setAllProductGroups();
        //             });
        //         }
        //     });
        // },
        onSubmit() {
            //
            this.formSubmit = true;

            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            // this.busySaving = true;
            //eslint-disable-next-line
            console.log("this form", this.form);
            Swal.fire({
                title: `Update group`,
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, update!",
            }).then((result) => {
                if (result.value) {
                    //type cast string to boolean
                    this.form.display_material == "false" ? (this.form.display_material = false) : (this.form.display_material = true);

                    this.saveProductGroup(this.form).then(() => {
                        this.busySaving = false;

                        Swal.fire(`Product group updated`, "", "success");

                        this.closeModal();

                        this.setAllProductGroups();
                    });
                } else {
                    this.busySaving = false;
                }
            });
        },
    },
    mounted() {
        this.form.id = this.selected_product_group_details.id;
        this.form.name = this.selected_product_group_details.name;
        this.form.description = this.selected_product_group_details.description;
        this.form.display_material = this.selected_product_group_details.display_material;
        this.form.linked_product_ids = this.selected_product_group_details.products.map((product) => {
            return product.id;
        });
        this.form.linked_role_ids = this.selected_product_group_details.roles.map((role) => {
            return role.id;
        });

        //eslint-disable-next-line
        console.log();
    },
};
</script>
<style></style>
