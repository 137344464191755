<template>
    <b-modal size="lg" title="Link products to this group" id="linkProductsToGroupModal" hide-footer>
        <div><LinkProductsToGroupTable ref="childLinkProductsToGroupTable" /></div>

        <div class="modal-footer">
            <b-button @click="onSubmit" variant="primary">Update Product Group</b-button>
            <button type="button" class="btn btn-secondary" @click="closeModal()">Close</button>
        </div>
    </b-modal>
</template>

<script>
import LinkProductsToGroupTable from "../tables/linkProductsToGroupTable.vue";
import { productComputed, productMethods } from "@/state/helpers";
//eslint-disable-next-line
import Swal from "sweetalert2";

export default {
    components: { LinkProductsToGroupTable },
    data: () => ({
        products: [],
    }),

    computed: {
        ...productComputed,
    },
    methods: {
        ...productMethods,
        closeModal() {
            this.$bvModal.hide("linkProductsToGroupModal");
        },
        // onSubmitData(products) {
        //     //eslint-disable-next-line
        //     console.log("produicts", products);
        //     for (let i = 0; i < this.products.length; i++) {
        //         this.productIds_to_save_to_group.push(products.id);
        //     }
        //     //eslint-disable-next-line
        //     console.log("product ids to send", this.productIds_to_save_to_group);
        // },
        onSubmit() {
            //
            this.formSubmit = true;

            // this.$v.form.$touch();
            // if (this.$v.form.$anyError) {
            //     return;
            // }
            this.busySaving = true;

            //eslint-disable-next-line
            console.log("refs", this.$refs.childLinkProductsToGroupTable.products);

            //eslint-disable-next-line

            // Swal.fire({
            //     title: `Update group`,
            //     text: "Are you sure?",
            //     icon: "warning",
            //     showCancelButton: true,
            //     confirmButtonColor: "#34c38f",
            //     cancelButtonColor: "#f46a6a",
            //     confirmButtonText: "Yes, update!",
            // }).then((result) => {
            //     if (result.value) {
            //         this.saveProductGroup(this.form).then(() => {
            //             this.busySaving = false;

            //             Swal.fire(`Product group updated`, "", "success");

            //             this.closeModal();

            //             this.setAllProductGroups();
            //         });
            //     } else {
            //         this.busySaving = false;
            //     }
            // });
        },
    },
    mounted() {},
};
</script>
<style></style>
